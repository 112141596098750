import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Env } from '../../../env/env';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private baseUrl: string = Env.baseUrl;

  constructor(
    private http: HttpClient) { }

  private _loading = new BehaviorSubject<boolean>(false);
  loading = this._loading.asObservable();
  loadingFlag: boolean = false;

  showLoading(flag: boolean) {
    this._loading.next(flag);
  }

  loadingStatus() {
    return this._loading;
  }

  private _msgTrigger = new BehaviorSubject<any>(null);
  msgTrigger = this._msgTrigger.asObservable();

  showMsg(status: string, msg: string) {
    this._msgTrigger.next({ status: status, msg: msg });
  }

  msgState() {
    return this._msgTrigger;
  }


}
